/**
* Return a list consisting of only the unique elements from the given list
* 
* Removes empty lists
* 
* @param {*[]} list 
* @returns {*[]}
*/
export function GetUniqueElements(list) {
    const filteredList = list.filter(item => !(Array.isArray(item) && item.length === 0));
    const uniqueSet = new Set(filteredList.map(JSON.stringify));
    const listOfUniqueElements = Array.from(uniqueSet, JSON.parse);
    return listOfUniqueElements;
}

/**
* Adds spaces to a given string after each capital letter 
* @param {string} str 
* @returns {string} input: HelloWorld -> output: Hello World
*/
export function addSpacesBetweenCapitals(str) {
    return str.replace(/([a-z])(?=[A-Z])/g, '$1 ').trim();
}

/**
* EXAMPLE: str1 = T2 DEQ FSE SAG, str2 = T2 FS SAG returns true
* EXAMPLE: str1 = T2 DEQ FSE SAG, str2 = T2 SAG FSE returns false
*
* @param {string} str1 Containing string
* @param {string} str2 String to compare against containing string
* @returns {boolean} true if str2 appears in str1 with all characters appearing in the same order
*/
export function containsInOrder(str1, str2) {
    let index = 0;
    for (let char of str1) {
        if (char === str2[index]) {
            index++;
        }
        if (index === str2.length) {
            return true;
        }
    }
    return false;
}

/**
 * WARN: Not to be used for any application where security is of import
 * 
 * Generate a unique Id whith specified length made up of uppercase and lowercase letters and number
 * 
 * @param {number} length default is 20 
 * @returns {string} 
 */
export function generateUniqueId(length = 20) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

/**
 * @param {string} DateOne yyyy-mm-dd
 * @param {string} DateTwo yyyy-mm-dd
 * @returns {boolean} true if DateOne is before or equal to DateTwo, false otherwise
 */
export function compareDates(DateOne, DateTwo) {
    const date1 = new Date(DateOne);
    const date2 = new Date(DateTwo);

    if (date1 <= date2) {
        return true;
    } else {
        return false;
    }
}

/**
 * @param {string} inputString
 * @return {string} with all backslashes escaped "\"" -> "\\\\"
 */
export function escapeBackslashesForMySQL(inputString) {
    return inputString.replace(/\\/g, '\\\\');
}

/**
 * @param {string} inputString
 * @return {string} "\\\...\\\" -> "\\"
 */
export function removeSequencesOfBackslashes(inputString) {
    return inputString.replace(/\\\\\\\\+/g, '\\\\');
}