import { defineStore } from "pinia";
import { useToolsStore } from './tools';
import { useMainStore } from './main';
import { useConfigStore } from './config';
import { useAlertStore } from "./alert";
import * as cornerstone3D from "@cornerstonejs/core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";
import axios from "axios";
import {
    utilities as csUtils,
} from '@cornerstonejs/core';
import { useEventsStore } from "./events";

// composables
import { formatDicomTime, GetAgeFromDicomDate } from "../composables/DicomUtils";

export const useUIStore = defineStore("ui", {
    state: () => ({
        //Modals
        meataDataModalOpen: false,
        settingsModalActive: false,
        aboutModalActive: false,
        //Expanded or Collapsed
        expanded: false,
        isMobile: false,
        showLayoutChanger: false,
        showPatientInfo: true,
        defaultLayouts: [],
        institutionNameAdded: false,
        centerName: "",
        screenWidth: 1920,
        screenHeight: 1080,
        // thumbnail component location
        thumbnailVertical: true,
        thumbnailLeft: true,
        thumbnailBottom: true,
        thumbnailHSize: '',
        thumbnailVSize: '',
        firstLayoutLoad: true,
        resize: false,
    }),
    getters: {
        doubleCount: (state) => {
            return state.counter * 2;
        },
        screenSize: (state) => {
            //Screen size small. Undecided yet
            if (state.screenWidth < 1000) {
                return "small"
            }
            //Screen size medium (pixels)
            else if (state.screenWidth < 1675) {
                return "medium"
            }
            //Screen size large
            else {
                return "large"
            }
        },
        orientation: (state) => {
            if (state.screenWidth < state.screenHeight) {
                return "portrait";
            } else {
                return "landscape";
            }
        }
    },
    actions: {
        ImageRenderedEventForElement(element) {
            var self = this;

            element.addEventListener(cornerstone3D.EVENTS.IMAGE_RENDERED, function (e) {
                var mainStore = useMainStore();
                var configStore = useConfigStore();
                const events = useEventsStore();
                const toolStore = useToolsStore();

                var element = e.target;
                var canvas = element;

                //if the element was removed from the target already
                if (!canvas.parentElement)
                    return;

                var renderingEngine = cornerstone3D.getRenderingEngine(element.attributes["data-rendering-engine-uid"].value);
                var viewport = renderingEngine.getViewport(element.attributes["data-viewport-uid"].value);

                const canvasId = element.attributes["data-viewport-uid"].value.split('_')[1];

                var currentImageIdIndex = viewport.getTargetImageIdIndex();
                var currentImageId = viewport.imageIds[currentImageIdIndex];
                var cwi = cornerstoneWADOImageLoader;
                var metadata = cwi.wadors.metaDataManager.get(viewport.imageIds[viewport.getTargetImageIdIndex()]);
                var studyUID = metadata["0020000D"].Value[0];
                var seriesUID = metadata["0020000E"].Value[0];

                toolStore.AddOrientationMarkersToViewport(viewport);

                //add the institution name
                if (!self.institutionNameAdded) {

                    self.institutionNameAdded = true;
                }

                var numberOfFrames = 1;
                if (metadata["00280008"] && Number.isInteger(metadata["00280008"])) {
                    numberOfFrames = metadata["00280008"];
                } else if (mainStore.GetAttribute(metadata["00280008"])) {
                    numberOfFrames = metadata["00280008"].Value[0];
                }

                var isMultiframe = false;
                if (numberOfFrames && numberOfFrames > 1) {
                    isMultiframe = true;
                }

                //check if it has the same image id but the frame number and if it has more then 1
                var isMultiframeViewport = viewport.imageIds[1] && viewport.imageIds[0].split("frames/")[0] == viewport.imageIds[1].split("frames/")[0] ? true : false;

                //bottom right
                var bottomLeft = canvas.parentElement.parentElement.children[1];
                var currentImageIndex = viewport.getCurrentImageIdIndex() + 1;
                var totalImages = viewport.imageIds.length;

                // if (!isMultiframeViewport && !mainStore.isEchoLayout)
                bottomLeft.innerHTML = `I: ${currentImageIndex}/${totalImages}`;

                //bottom left
                if (viewport.getProperties().voiRange) {
                    var bottomRight = canvas.parentElement.parentElement.children[2];
                    var lower = viewport.getProperties().voiRange.lower;
                    var upper = viewport.getProperties().voiRange.upper;

                    var wwwc = csUtils.windowLevel.toWindowLevel(lower, upper);
                    bottomRight.innerHTML = `W: ${parseInt(wwwc.windowWidth)}  L: ${parseInt(wwwc.windowCenter)}`;
                }

                const vpIndex = events.viewportProperties.findIndex(x => x.id === viewport.id);

                if (vpIndex != -1 && viewport.getZoom() == 1) {
                    const vp = events.viewportProperties[vpIndex];

                    viewport.setZoom(vp.zoomFactor);
                    // viewport.setPan(vp.pan);

                    // if(!vp.dontClear) {
                    //     events.clearViewportProperty(viewport.id);
                    // } 

                    viewport.render();
                    return;
                }

                //top left
                var topLeft = canvas.parentElement.parentElement.children[3];
                var study = mainStore.studiesLoaded.filter(x => x.studyUID == studyUID)[0];
                var series = study.series.filter(x => x.seriesUID == seriesUID)[0];
                var seriesDescription = series.seriesDescription ? series.seriesDescription.slice(0, 25) : "";
                var seriesNumber = series.seriesNumber;

                var seriesDate = mainStore.GetAttribute(metadata["00080021"]);
                var seriesTime = mainStore.GetAttribute(metadata["00080031"]);
                var year = seriesDate.substring(0, 4);
                var month = seriesDate.substring(4, 6);
                var day = seriesDate.substring(6, 8);

                var patientName = study.patientName;
                topLeft.innerHTML = "";
                if (configStore.canvasPatientDetails.name) {
                    topLeft.innerHTML += `${patientName} <br/>`;
                }

                if (configStore.canvasPatientDetails.desc) {
                    topLeft.innerHTML += `${seriesDescription} <br/> `;
                }

                topLeft.innerHTML += `Date ${month}-${day}-${year} <br/>`;
                topLeft.innerHTML += `Time ${formatDicomTime(seriesTime)} <br/>`;

                if (configStore.canvasPatientDetails.seriesNumber) {
                    topLeft.innerHTML += `Series ${seriesNumber} <br/>`;
                }
                if (configStore.canvasPatientDetails.age) {
                    var age = GetAgeFromDicomDate(mainStore.studiesLoaded[mainStore.selectedStudy - 1].DOB);
                    topLeft.innerHTML += `Age ${Number.isInteger(age) ? age : "-"} <br/>`;
                }
                if (configStore.canvasPatientDetails.sex) {
                    var sex = metadata["00100040"] ? mainStore.GetAttribute(metadata["00100040"]) : "-";
                    topLeft.innerHTML += `${sex}`;
                }

                if (study.prior) {
                    topLeft.innerHTML += `PRIOR`;
                }

                //top right -- CINE
                var topRight = canvas.parentElement.parentElement.children[4];

                var currentFrameNumber = isMultiframeViewport ? currentImageIndex : 1;
                //var buttonsHTML = `<span>F: ${currentFrameNumber}/${numberOfFrames}</span><br /><div class="cine-controls">`;
                //`<div class="play-image"><svg width="21" height="21" style="fill:#d3d3d3;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M3 5.49686C3 3.17662 5.52116 1.73465 7.52106 2.91106L18.5764 9.41423C20.5484 10.5742 20.5484 13.4259 18.5764 14.5858L7.52106 21.089C5.52116 22.2654 3 20.8234 3 18.5032V5.49686Z" /></svg></div>`;
                //`<div class="stop-image"><svg width="21" height="21" style="fill:#d3d3d3;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M4 18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12z" /></svg></div>` +
                //`<div class="exit-image"><svg width="21" height="21" style="fill:#d3d3d3;" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/></svg></div></div>`;
                if (isMultiframe && !mainStore.isEchoLayout) {
                    //topRight.innerHTML = buttonsHTML;

                    // //play
                    // topRight.querySelector(".play-image").removeEventListener("click", Play);
                    // topRight.querySelector(".play-image").addEventListener("click", Play);

                    // ////stop
                    // topRight.querySelector(".stop-image").addEventListener("click", Stop);

                    // //exit
                    // topRight.querySelector(".exit-image").removeEventListener("click", Exit);
                    // topRight.querySelector(".exit-image").addEventListener("click", Exit);
                } else if (mainStore.isEchoLayout) {
                    if (metadata["00181088"]) {
                        topRight.innerHTML = `${metadata["00181088"].Value[0]} BPM <br/>`;
                        topRight.innerHTML += `${metadata["00082127"].Value[0]} <br/>`;
                        topRight.innerHTML += `${metadata["00082120"].Value[0]} <br/>`;
                    } else {
                        topRight.innerHTML = "";
                    }
                } else
                    topRight.innerHTML = "";


                function Play() {
                    mainStore.CinePlay(viewport);
                }

                function Stop() {
                    alert("stop")
                    mainStore.CineSoftStop(viewport);
                }

                function Exit() {
                    mainStore.CineStop(viewport);
                }


            });
        },
        /**
         * Calculate age in years from date string 
         * @param {*} dateString yyyy-mm-dd
         * @returns age
         */
        getAgeFromDateString(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        setCookie(name, value, days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        },
        getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },
        eraseCookie(name) {
            document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },
        SetActiveTool(toolName, mouseButton) {
            //Enable active tool
            const toolStore = useToolsStore();
            toolStore.ActivateTool(toolName, mouseButton);
        },
        /**
         * 
         * @param {Layout} layout 
         * @returns 
         */
        SetLayoutRowCol(layout) {
            const alertStore = useAlertStore();

            const mainStore = useMainStore();

            var canvasElement = document.getElementById("canvases") || document.getElementById("canvases-right") || document.getElementById("canvases-bottom") || document.getElementById("canvases-top");
            if (!canvasElement) return;

            if (this.firstLayoutLoad && !mainStore.vrMode && !mainStore.mprMode) {
                this.firstLayoutLoad = false;
            }

            var totalCanvases = canvasElement.children.length;
            mainStore.totalCanvases = totalCanvases;

            var row = layout.row;
            var col = layout.col;
            var portraitTrue = window.matchMedia("(orientation: portrait)").matches;
            //If screen is portrait limit layout to 5x3 (colsXrows)
            if (portraitTrue) {
                let rowFlag = false;
                const rowWarning = 'Layout rows limited to maximum of 5 due to portrait orientation';
                let colFlag = false;
                const colWarning = 'Layout columns limited to maximum of 3 due to portrait orientation';

                const rowXcolWarning = 'Layout limited to maximum of 3x5 (rowsXcols) due to portrait orientation';

                if (row > 5) {
                    row = 5;
                    rowFlag = true;
                }
                if (col > 3) {
                    col = 3;
                    colFlag = true;
                }

                if (rowFlag && colFlag) {
                    alertStore.showFailureAlert({ text: rowXcolWarning });
                }
                else if (rowFlag) {
                    alertStore.showFailureAlert({ text: rowWarning });
                }
                else if (colFlag) {
                    alertStore.showFailureAlert({ text: colWarning });
                }
                portraitTrue = true;
            }
            else {
                //If screen is landscape limit layout to 4x8 (rowsXcols)
                let rowFlag = false;
                const rowWarning = 'Layout rows limited to maximum of 4 due to landscape layout';
                let colFlag = false;
                const colWarning = 'Layouts columns limited to maximum of 8 due to landscape layout';

                const rowXcolWarning = 'Layout limited to maximum of 4x8 (rowsXcols) due to landscape layout';

                if (row > 4) {
                    row = 4;
                    rowFlag = true;
                }
                if (col > 8) {
                    col = 8;
                    colFlag = true;
                }

                if (rowFlag && colFlag) {
                    alertStore.showFailureAlert({ text: rowXcolWarning });
                }
                else if (rowFlag) {
                    alertStore.showFailureAlert({ text: rowWarning });
                }
                else if (colFlag) {
                    alertStore.showFailureAlert({ text: colWarning });
                }
                portraitTrue = false;
            }

            var rowscols = `${row}x${col}`;

            if (rowscols != mainStore.prevLayout && rowscols != "1x1")
                mainStore.focusSeries = false;

            mainStore.SetLayout(rowscols);
            canvasElement.style.gridTemplateRows = `repeat(${row}, auto)`;
            canvasElement.style.gridTemplateColumns = `repeat(${col}, auto)`;

            this.resize = true;
        },
        /**
         * Update size of patient information text based on number of rows in layout
         * 
         * @param {*} rows integer number of rows
         * @param {*} portrait true if portrait, false if landscape
         */
        updatePatientInformationFontSizeForLayout(rows, portrait) {
            var canvasElement = document.getElementById("canvases") || document.getElementById("canvases-right") || document.getElementById("canvases-bottom") || document.getElementById("canvases-top");

            var fontSize = 15;
            var topPercent = 97;
            var PIfontSize = 14;
            //Orientation specific 
            if (!portrait) {
                switch (rows) {
                    case "2":
                        topPercent = 95;
                        fontSize = 13;
                        PIfontSize = 13;
                        break;
                    case "3":
                        topPercent = 93;
                        fontSize = 12;
                        PIfontSize = 12;
                        break;
                    case "4":
                        topPercent = 90;
                        fontSize = 11;
                        PIfontSize = 11;
                        break;
                    default:
                        // Handle the case when 'row' doesn't match any of the specified values
                        break;
                }

            }
            //Portrait + Thumbnail bar location
            else if (this.thumbnailVertical) {
                switch (rows) {
                    case "1":
                        topPercent = 98;
                        break;
                    case "2":
                        topPercent = 97;
                        fontSize = 13;
                        PIfontSize = 13;
                        break;
                    case "3":
                        topPercent = 95;
                        fontSize = 12;
                        PIfontSize = 12;
                        break;
                    case "4":
                        topPercent = 94;
                        fontSize = 11;
                        PIfontSize = 11;
                        break;
                    case "5":
                        topPercent = 93;
                        fontSize = 11;
                        PIfontSize = 11;
                        break;
                    default:
                        // Handle the case when 'row' doesn't match any of the specified values
                        break;
                }
            } else {
                switch (rows) {
                    case "1":
                        topPercent = 97;
                        break;
                    case "2":
                        topPercent = 96;
                        fontSize = 13;
                        PIfontSize = 13;
                        break;
                    case "3":
                        topPercent = 94;
                        fontSize = 12;
                        PIfontSize = 12;
                        break;
                    case "4":
                        topPercent = 93;
                        fontSize = 11;
                        PIfontSize = 11;
                        break;
                    case "5":
                        topPercent = 90;
                        fontSize = 11;
                        PIfontSize = 11;
                        break;
                    default:
                        // Handle the case when 'row' doesn't match any of the specified values
                        break;
                }
            }

            for (let index = 0; index < canvasElement.children.length; index++) {
                const canvas = canvasElement.children[index];

                var br = canvas.querySelectorAll(".patient-info")[0];
                var bl = canvas.querySelectorAll(".patient-info")[1];
                var tl = canvas.querySelectorAll(".patient-info")[2];
                //var tr = canvas.querySelectorAll(".patient-info")[3];

                var left = canvas.querySelector(".left-middle");
                var leftSecondary = canvas.querySelector(".left-middle .secondary-marker");

                var right = canvas.querySelector(".right-middle");
                var rightSecondary = canvas.querySelector(".right-middle .secondary-marker");

                var top = canvas.querySelector(".top-middle");
                var topSecondary = canvas.querySelector(".top-middle .secondary-marker");

                var bottom = canvas.querySelector(".bottom-middle");
                var bottomSecondary = canvas.querySelector(".bottom-middle .secondary-marker");

                if (left && right && top && bottom) {
                    left.style.fontSize = `${fontSize}px`;
                    leftSecondary.style.fontSize = `${fontSize - 4}px`;

                    right.style.fontSize = `${fontSize}px`;
                    rightSecondary.style.fontSize = `${fontSize - 4}px`;

                    top.style.fontSize = `${fontSize}px`;
                    topSecondary.style.fontSize = `${fontSize - 4}px`;

                    bottom.style.fontSize = `${fontSize}px`;
                    bottomSecondary.style.fontSize = `${fontSize - 4}px`;

                    top.style.bottom = `${topPercent}%`;
                }

                if (br && bl && tl) {
                    br.style.fontSize = `${PIfontSize}px`;
                    bl.style.fontSize = `${PIfontSize}px`;
                    tl.style.fontSize = `${PIfontSize}px`;
                }
            }
        },
        async UpdateDefaultTools(defaults) {

            //temp solution
            var config = useConfigStore();
            var postURL = `${config.defaultsRoot}/SaveDefaultTools`;

            for (let index = 0; index < defaults.length; index++) {
                const temp = defaults[index];

                let data = new FormData();
                data.append("modality", temp.modality);
                data.append("control", temp.control);
                data.append("tool", temp.tool);
                var res = await axios.post(postURL, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(response => {
                        // Handle the response from the server
                        console.log("Response:", response.data);
                    })
                    .catch(error => {
                        // Handle any errors that occurred during the request
                        console.error("Error:", error);
                    });
            }

            //this.defaultLayouts = defaults;
        },
        async UpdateDefaultThumbnails() {
            const defaultThumbs = {
                thumbnailHSize: this.thumbnailHSize,
                thumbnailVSize: this.thumbnailVSize,
                thumbnailHBottom: this.thumbnailBottom,
                thumbnailVleft: this.thumbnailLeft,
            }
            
            localStorage.setItem("defaultThumb", JSON.stringify(defaultThumbs));
        },
        SettingsModalOpen() {
            this.settingsModalActive = true;
        },
        SettingsModalClose() {
            this.settingsModalActive = false;
        },
        AboutModalOpen() {
            this.aboutModalActive = true;
            alert("about")
        },
        PrintModalOpen() {
            this.aboutModalActive = true;
            alert("Print")
        },
        ExportModalOpen() {
            this.aboutModalActive = true;
            alert("Export")
        },
        BurnCDModalOpen() {
            this.aboutModalActive = true;
            alert("Burn CD")
        },
        AboutModalClose() {
            this.aboutModalActive = false;
        },
        Expand() {
            this.expanded = true;

            //CSS Changes for Expanding
            document.getElementById("viewer-content").style.gridColumnStart = "1";
            document.getElementById("viewer-content").style.gridColumnEnd = "5";
            document.getElementById("viewer-container").style.gridTemplateColumns = "160px auto";
            document.getElementById("main-layout").style.gridTemplateRows = "60px";
            document.getElementById("toolbar").style.gridTemplateRows = "60px";

            this.ToggleFullscreenForElement(document.getElementById("app"));
        },
        Collapse(dontToggle) {
            this.expanded = false;

            //CSS Changes for Collapsing
            document.getElementById("viewer-content").style.gridColumnStart = "2";
            document.getElementById("viewer-content").style.gridColumnEnd = "5";
            document.getElementById("viewer-container").style.gridTemplateColumns = "240px auto";
            document.getElementById("main-layout").style.gridTemplateRows = "80px";
            document.getElementById("toolbar").style.gridTemplateRows = "80px";

            if (!dontToggle)
                this.ToggleFullscreenForElement(document.getElementById("app"));
        },
        ToggleFullscreenForElement(container) {
            if (document.fullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (!document.mozFullScreen && !document.webkitFullScreen) {
                    if (container.requestFullscreen) {
                        container.requestFullscreen();
                    } else if (container.mozRequestFullScreen) {
                        container.mozRequestFullScreen();
                    } else if (container.webkitRequestFullScreen) {
                        container.webkitRequestFullScreen();
                    } else if (container.msRequestFullscreen) {
                        container.msRequestFullscreen();
                    }
                }
            }
        }
    }
});