/**
* Converts object with key value pairs into a list of status strings 
* 
* @param {object} statuses {<statusCode>: <numberOfInstancesReturned>}
* @returns {string[]} ["n images returned status code y"]
*/
export function convertStatusCodesToStrings(statuses) {
    const resultStrings = [];

    for (const statusCode in statuses) {
        const count = statuses[statusCode];

        const hexNumber = parseInt(statusCode).toString(16);

        let resultString = `${count} images returned status code ${hexNumber}`;

        if (hexNumber === "ff00") resultString += " (pending)";
        if (hexNumber === "0") resultString += " (success)";
        if (hexNumber.split("")[0] === "c") resultString += " (failure)";
        if (hexNumber === "0122") resultString += " (refused: SOP class not supported)";
        if (hexNumber === "A900") resultString += " (error: data does not match SOP class)";
        if (hexNumber === "A700") resultString += " (refused: out of resources)";

        resultStrings.push(resultString);
    }

    return resultStrings;
}

/**
* 
*
* @param {object} statuses {<statusCode>: <numberOfInstancesReturned>}
* @param {string} studyUID
* @returns {string} status
*/
export function getStatus(statuses, studyUID) {

    let partialSuccess = false;
    let failure = false;
    let pending = false;

    let foundSuccess = false
    for (const statusCode in statuses) {
        const hexNumber = parseInt(statusCode).toString(16);

        if (hexNumber === "0") foundSuccess = true;

        else if (hexNumber === "ff00") pending = true;

        else failure = true;
    }

    if (pending) return "pending";
    if (foundSuccess && !partialSuccess && !failure) return "success"
    if (partialSuccess) return "partial"
    if (failure) return "failure"
}

/**
 * Logs verbose dicom c-get status 
 * 
* @param {object} statuses {<statusCode>: <numberOfInstancesReturned>}
 * @param {string} studyUID 
 */
export function logStatus(statuses, studyUID) {
    const statusStrings = convertStatusCodesToStrings(statuses);

    const status = getStatus(statuses, studyUID);

    let formattedString;
    switch (status) {
        case "pending":
            formattedString = `Study pull (${studyUID}) is pending:\n`;
            break;
        case "failure":
            formattedString = `Pulling study ${studyUID} failed and returned:\n`
            break;
        case "partial":
            formattedString = `Attempted pull of study ${studyUID} partial success:\n`;
            break;
        case "success":
            formattedString = `Pulling study ${studyUID} successfully returned:\n`;
            break;
        default:
            formattedString = `${studyUID} returned:\n`;
            break;
    }

    for (let i = 0; i < statusStrings.length; i++) {
        formattedString += "\t> " + statusStrings[i] + "\n";
    }

    console.log(formattedString);
}

/**
 * @param {string} time hhmmss.<unused>
 * @returns {string} hh:mm:ss
 */
export function formatDicomTime(time) {
    var hh = time.substring(0, 2);
    var mm = time.substring(2, 4);
    var ss = time.substring(4, 6);

    return `${hh}:${mm}:${ss}`;
}

/**
* @param {string} date yyyymmdd
* @returns {string} mm-dd-yyyy
*/
export function formatDicomDate(date) {
    // Create a regular expression for the "yyyy-mm-dd" format
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    // Test the inputString against the regular expression
    const alreadyFormatted = regex.test(date);

    if (alreadyFormatted) {
        return date;
    }

    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);

    return `${year}-${month}-${day}`;
}

/**
 * Get formatted dicom date from date string
 * @param {*} date yyyy-mm-dd
 * @returns yyyymmdd
 */
export function formatDateStringToDicomDate(date) {
    // Create a regular expression for the "yyyy-mm-dd" format
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    // Test the inputString against the regular expression
    const isDateString = regex.test(date);

    if (!isDateString) {
        return "";
    }

    var year = date.substring(0, 4);
    var month = date.substring(5, 7);
    var day = date.substring(8, 10);

    return `${year}${month}${day}`;
}

/**
* Calculate years since dicom date
* @param {string} date yyyymmdd
* @returns {number} years
*/
export function GetAgeFromDicomDate(date) {
    const today = new Date();

    const dateString = formatDicomDate(date);

    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

/**
 * Get DICOM abbreviated version of viewposition string 
 * 
 * AP - Anterior/Posterior
 * 
 * PA - Posterior/Anterior
 * 
 * LL - Left Lateral
 * 
 * RL - Right Lateral
 * 
 * RLDL - Right Lateral Decubitus
 * 
 * LLDL - Left Lateral Decubitus
 * 
 * RLO - Right Lateral Oblique
 * 
 * LLO - Left Lateral Oblique
 * 
 * @param {*} viewPosition Full view position string 
 * @returns Abbreviated view position string
 */
export function getAbbreviatedViewPosition(viewPosition) {
    let abbreviated = "";

    switch (viewPosition) {
        case "Anterior/Posterior":
            abbreviated = "AP";
            break;
        case "Posterior/Anterior":
            abbreviated = "PA";
            break;
        case "Left Lateral":
            abbreviated = "LL";
            break;
        case "Right Lateral":
            abbreviated = "RL";
            break;
        case "Right Lateral Decubitus":
            abbreviated = "RLDL";
            break;
        case "Left Lateral Decubitus":
            abbreviated = "LLDL";
            break;
        case "Right Lateral Oblique":
            abbreviated = "RLO";
            break;
        case "Left Lateral Oblique":
            abbreviated = "LLO";
            break;
        default:
            break;
    }

    return abbreviated;
}