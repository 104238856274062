import { defineStore } from "pinia";

import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css";

export const useAlertStore = defineStore("alert", {
    state: () => ({
        activeAlerts: [],
    }),
    getters: {

    },
    actions: {
        showAlert({ text, duration, close, gravity, position, style }) {
            const toast = Toastify({
                text,
                duration,
                close,
                gravity, // `top` or `bottom`
                position, // `left`, `center` or `right`
                style, 
                onClick: () => {
                    toast.hideToast();
                }
            }).showToast();

            this.handleActiveAlerts(toast);
        },
        /**
         * Show a green popup to indicate success
         * 
         * @param {*} param0 { text = "", duration = 5000, close = false, gravity = "top", position = "right" }
         */
        showSuccessAlert({ text = "", duration = 5000, close = false, gravity = "top", position = "right" }) {
            this.showAlert({
                text, duration, close, gravity, position, style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                }
            });
        },
        /**
         * Show a red popup to indicate failure
         * 
         * @param {*} param0 { text = "", duration = 5000, close = false, gravity = "top", position = "right" }
         */
        showFailureAlert({ text = "", duration = 5000, close = false, gravity = "top", position = "right" }) {
            this.showAlert({
                text, duration, close, gravity, position, style: {
                    background: "linear-gradient(to right, #990000, #4d0000)",
                }
            });
        },
        /**
         * Show a blue popup to indicate neutral response
         * 
         * @param {*} param0 { text = "", duration = 5000, close = false, gravity = "top", position = "right" }
         */
        showNeutralAlert({ text = "", duration = 5000, close = false, gravity = "top", position = "right" }) {
            this.showAlert({
                text, duration, close, gravity, position, style: {
                    background: "linear-gradient(to right, #0080ff, #00ffff)",
                }
            });
        },
        handleActiveAlerts(alert) {
            this.activeAlerts.push(alert);

            // Check if the number of active toasts exceeds the desired limit
            const maxToasts = 2; // Set your desired maximum limit
            if (this.activeAlerts.length > maxToasts) {
                // Remove the oldest toast
                const oldestAlert = this.activeAlerts.shift();
                oldestAlert.hideToast();
            }
        },
    }
});