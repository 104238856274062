<template>
  <router-view />
</template>

<script>
import { onMounted, ref } from "vue";
import { useUIStore } from "./store/ui";
import { useConfigStore } from "./store/config";
import { useMainStore } from './store/main';

// composables
import { getRenderingEngine } from "./composables/CornerstoneUtils";

export default {
  name: "App",
  setup() {
    const root = ref(null);
    window.name = "WebViewerV3";

    /***** configure thumbnail location based on config *****/
    const uiStore = useUIStore();
    const configStore = useConfigStore();
    const main = useMainStore();

    const thumbnailDefault = configStore.thumbnailsDefault;

    const handleThumbnailOrientation = () => {
      if (window.matchMedia("(orientation: landscape)").matches) {
        if (thumbnailDefault.enabled) {
          uiStore.thumbnailVertical = true;
        }
      } else {
        if (thumbnailDefault.enabled) {
          uiStore.thumbnailVertical = false;
        }
      }
    };

    const handleThumbnailSize = () => {
      if (thumbnailDefault.enabled) {
        uiStore.thumbnailHSize = thumbnailDefault.sizeH;
        uiStore.thumbnailVSize = thumbnailDefault.sizeV;
      }
    }

    handleThumbnailOrientation();

    handleThumbnailSize();

    window.addEventListener("resize", function () {
      if (thumbnailDefault.reactive) {
        handleThumbnailOrientation();
      }
    });

    //handling the ESC key fullscreen exit
    window.addEventListener("fullscreenchange", () => {
      setTimeout(() => {
        resizeViewports();
      }, 500)
    })
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    function exitHandler(e) {
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        const store = useUIStore();
        store.Collapse(true);

        if (document.exitFullscreen) {
          //document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    }

    const resizeViewports = (e) => {
      const renderingEngineId = "MCRenderingEngine";
      let renderingEngine = getRenderingEngine(renderingEngineId);
      if (renderingEngine) {
        setTimeout(() => {
          renderingEngine.resize();
          uiStore.SetLayoutRowCol(main.layout);
        }, 250)
      }


    }

    onMounted(async () => {
      document.title = "webviewerV3.1"; // default
      window.name = "webviewerV3.1";

      window.addEventListener("resize", resizeViewports);
    })

    return {
      root,
      uiStore,
      configStore,
      thumbnailDefault,
      handleThumbnailOrientation,
      handleThumbnailSize,
    };
  },
};
</script>

<style>
body {
  margin: 0px;
  padding: 0px;
}

#app {
  /* font-family: Lato, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, Helvetica Neue, Arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; */
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #162a4a;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0px;
  padding: 0px;
}

.warning-icon {
  background: url("https://freesvg.org/img/warning.png");
  /* Other styles here */
}

.sr-warning {
  color: rgb(211, 183, 57);
  font-size: 14px;
  margin-top: 30px;
  text-align: center;
}

.sr-attribute {
  margin-bottom: 5px;
}

.sr-data {
  position: relative;
  left: 20px;
  top: 20px;
}

.sr-data-title {
  padding-top: 0px;
  text-align: center;
  font-weight: 500;
  font-size: large;
  color: #d3d3d3;
}

.sr-canvas {
  color: #d3d3d3;
  background: black;
  contain: strict;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sr-canvas::-webkit-scrollbar {
  width: 9px;
}

.sr-canvas::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #76ebfd54;
}

.sr-thumb {
  width: 100%;
  height: 100%;
  background: black;
  font-weight: 500;
  text-align: center;
}

.sr-title {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.canvas-container svg line {
  filter: none !important;
}

.canvas div {
  cursor: default !important;
}

.nocursor {
  cursor: default !important;
}

.cine-controls {
  display: block;
}

.play-image:hover {
  fill: #fff !important;
}

.play-image:hover>svg {
  fill: rgb(79, 142, 184) !important;
}

.stop-image:hover {
  fill: #fff !important;
}

.stop-image:hover>svg {
  fill: rgb(179, 148, 90) !important;
}

.exit-image:hover {
  fill: #fff !important;
}

.exit-image:hover>svg {
  fill: rgb(182, 92, 134) !important;
}

#fps-slider {
  width: 80px !important;
}

#thumbnails>div>div.series-thumbnail-container>div>div.series-thumbnail-image>div>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* animation: blinkingBackground 2s infinite; */
  background-color: #242323;
}

#thumbnails-right>div>div.series-thumbnail-container>div>div.series-thumbnail-image>div>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* animation: blinkingBackground 2s infinite; */
  background-color: #242323;
}

#thumbnails-bottom>div>div.series-thumbnail-container>div>div.series-thumbnail-image>div>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* animation: blinkingBackground 2s infinite; */
  background-color: #242323;
}

#thumbnails-top>div>div.series-thumbnail-container>div>div.series-thumbnail-image>div>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* animation: blinkingBackground 2s infinite; */
  background-color: #242323;
}

@keyframes blinkingBackground {
  0% {
    background-color: #242323;
  }

  100% {
    background-color: #2b2b2b;
  }
}

input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

div:focus {
  outline: none;
}

h1 {
  cursor: default;
}

select {
  cursor: pointer;
}
</style>
