// cornerstone imports
import { RenderingEngine } from '@cornerstonejs/core';
import * as cornerstoneTools from '@cornerstonejs/tools';
import * as cornerstone from "@cornerstonejs/core";

// vue imports
import { ref } from "vue";

const _toolgroupIds = ref({});

export function setToolGroupIds(toolGroupIds) {
    _toolgroupIds.value = toolGroupIds;
}

/**
 * Retrieve rendering engine with corresponding id
 * 
 * If the rendering engine cannot be found create a new one
 * 
 * @param {*} renderingEngineId 
 * 
 * @returns rendering engine
 */
export function getRenderingEngine(renderingEngineId) {
    let renderingEngine;
    if (cornerstone.getRenderingEngine(renderingEngineId)) {
        renderingEngine = cornerstone.getRenderingEngine(renderingEngineId);
    } else {
        renderingEngine = new RenderingEngine(renderingEngineId);
    }

    return renderingEngine;
}

/**
 * Remove viewport from tool group and disable element
 * 
 * @param {string} viewportId 
 * @param {RenderingEngine} renderingEngine
 */
export function destroyStackViewport(viewportId, renderingEngine) {
    if (!viewportId || !renderingEngine) {
        console.warn("Failed to destroy viewport");
        return;
    }

    const { ToolGroupManager } = cornerstoneTools;

    const viewport = renderingEngine.getViewport(viewportId);

    if (!viewport) return

    const toolGroupId = _toolgroupIds.value.stack.id;
    const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);

    // destroy viewport and remove from toolgroup
    toolGroup.removeViewports(renderingEngine.id, viewportId);
    renderingEngine.disableElement(viewportId);
}