import { defineStore } from "pinia";
import { useConfigStore } from './config';
import { useUIStore } from './ui';
import axios from "axios";

import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";
import * as cornerstoneTools from '@cornerstonejs/tools';

import { useToolsStore } from "./tools";
import { config } from "@cornerstonejs/tools/dist/esm/stateManagement/annotation";

// composables
import { GetUniqueElements, compareDates } from "../composables/PureUtils";
import { formatDicomDate } from "../composables/DicomUtils";
import GetImagePlaneFromVectorCosines from "../composables/GetImagePlaneFromVectorCosines";
import getNumberOfPriors from "../composables/GetNumberOfPriors";

// classes
import Layout from "../classes/Layout";
import { useAlertStore } from "./alert";

export const useMainStore = defineStore("main", {
    state: () => ({
        studiesLoaded: [],
        imagesLoaded: false,
        preDataLoaded: false,
        selectedCanvas: -1,
        totalCanvases: -1,
        currentStudy: "",
        currentSeries: "",
        mprMode: false,
        mprStudyUID: "",
        mprSeriesUID: "",
        vrMode: false,
        selectedSeries: 1,
        selectedSeriesElement: null,
        selectedStudy: 1,
        studyBefore3D: null,
        cachedSeriesMetadata: [],
        currentLayout: "1x1",
        layout: new Layout({}),
        prevLayout: "",
        fromSeries: 1,
        toSeries: 1,
        focusSeries: false,
        focusedStudyUID: "",
        focusedSeriesUID: "",
        firstCanvasLoad: true,
        canvases: new Array(32),
        layoutBefore3D: "",
        relatedStudies: [],
        seriesFPS: 0,
        cinedata: [],
        currentEchoView: "",
        currentEchoStage: "",
        echoSeries: [],
        autoStartEcho: true,
        byView: true,
        isEchoLayout: false,
        checkForStressEcho: false,
        selectedEchoIndex: 0,
        syncStacks: true,
        cacheImages: true,
        allThumbnailsLoaded: false,
        cachedSeriesUIDs: [],
        mainSeriesUIDs: [],
        priorSeriesUIDs: [],
        imageIdsCache: [],
        VRLastStudyUID: '',
        VRLastSeriesUID: '',
        VRImageIdsCache: [],
        VRVolumeCache: null,
        multiSelectedViewports: [],
        multiSelectedViewportCache: [],
        defaults: [],
        currentPreset: '',
        thumbnailDefaults: [],
        multiSeriesScrolling: false,
        scrollingCanvasId: [],
    }),
    actions: {
        /**
         * Display specified series in canvas with provided canvas id
         * 
         * @param {*} studyUID 
         * @param {*} seriesUID 
         * @param {*} canvasId 
         */
        handleChangeSeriesSpecific(studyUID, seriesUID, canvasId) {
            this.unselectSeries();
            this.scrollingCanvasId.push(canvasId);

            this.canvases[canvasId - 1] = {
                id: canvasId,
                type: "STACK",
                seriesUID: seriesUID,
                studyUID: studyUID,
                imageIds: []
            }
        },
        /**
         * Scroll selected series in given direction
         * 
         * If scrolling up increment series number to next available
         * 
         * If scrolling down decrement series number to next available
         * 
         * @param {Boolean} up 
         */
        handleSeriesScroll(up) {
            const alert = useAlertStore();

            function removeTimer(self) {
                if (self.seriesScrollTimeout) {
                    clearTimeout(self.seriesScrollTimeout);
                    self.seriesScrollTimeout = null;
                }
            }

            if (this.scrollingCanvasId.length > 0) {
                alert.showFailureAlert({ text: "Viewport is loading, please try again later" });
                if (!this.seriesScrollTimeout) {
                    this.seriesScrollTimeout = setTimeout(() => {
                        this.scrollingCanvasId = [];
                        removeTimer(this);
                    }, 1500);
                }
                return;
            }

            removeTimer(this);

            const isMultiSelect = this.multiSelectedViewports.length > 0;
            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                let count = visibleMultiSelected.length;
                for (let i = 0; i < count; i++) {
                    const viewportElement = visibleMultiSelected[i].querySelector("[data-viewport-uid]");
                    this.SeriesScrollHelper(viewportElement, up, true);
                }
            } else if (this.selectedSeriesElement) {
                let elem = this.selectedSeriesElement.parentElement.parentElement;

                this.SeriesScrollHelper(elem, up, false);
            } else {
                alert.showFailureAlert({ text: "A series must be selected to series scroll" });
            }
        },
        /**
         * Handle actual incrementation or decrementation of displayed series number and re render canvas
         * 
         * @param {*} elem 
         * @param {*} up 
         * @param {*} multi 
         * @returns 
         */
        SeriesScrollHelper(elem, up, multi = false) {
            const alert = useAlertStore();
            if (!elem) {
                alert.showFailureAlert({ text: "Failed to retrieve selected element" });
                return;
            }

            let id;
            try {
                id = elem.attributes["data-viewport-uid"].value;
            } catch (err) {
                alert.showFailureAlert({ text: "Failed to retrieve canvas id" });
                return;
            }
            const canvasId = parseInt(id.split('_')[1]);

            const canvas = this.canvases.find(x => x.id == canvasId);

            if (!canvas) {
                alert.showFailureAlert({ text: "Failed to retrieve selected canvas" });
                return;
            }

            const studyUID = canvas.studyUID;

            const study = this.studiesLoaded.find(x => x.studyUID === studyUID);
            const series = study.series.find(x => x.seriesUID == canvas.seriesUID);

            let allSeriesCopy = JSON.parse(JSON.stringify(study.series));
            allSeriesCopy.sort((a, b) => parseInt(a.seriesNumber) - parseInt(b.seriesNumber));
            const selectedSeriesIndex = allSeriesCopy.findIndex(x => x.seriesNumber === series.seriesNumber);

            // no series to scroll to
            if ((selectedSeriesIndex == 0 && !up) || (selectedSeriesIndex == allSeriesCopy.length - 1 && up)) {
                alert.showNeutralAlert({ text: `Already at the ${up ? "last" : "first"} series in this study` });
                return;
            }

            const nextIndex = up ? selectedSeriesIndex + 1 : selectedSeriesIndex - 1;

            const nextSeriesUID = allSeriesCopy[nextIndex].seriesUID;

            if (multi) this.multiSeriesScrolling = true;
            this.handleChangeSeriesSpecific(studyUID, nextSeriesUID, canvasId);
        },
        /**
         * Handle toggling between focused and unfocused status. When a series is focused it will be displayed on its own
         * in a 1x1 grid
         * 
         * @param {*} studyUID 
         * @param {*} seriesUID 
         */
        toggleFocusedSeries(studyUID, seriesUID) {
            const ui = useUIStore();

            //change layout
            if (this.focusSeries && this.layout.toString() == "1x1") { //already focused
                if (this.focusedStudyUID != studyUID) { // focus new study
                    this.focusedStudyUID = studyUID;
                }

                if (this.focusedSeriesUID != seriesUID) { // focus new series

                    this.focusedSeriesUID = seriesUID;

                } else { // toggle focus off
                    this.layout.setLayoutFromString(this.prevLayout);

                    this.focusSeries = false;
                }
            } else { // focus
                this.prevLayout = this.layout.toString(); // cache current layout

                this.focusedStudyUID = studyUID;
                this.focusedSeriesUID = seriesUID;

                this.layout.setLayoutFromString("1x1");

                this.focusSeries = true;
            }

            ui.SetLayoutRowCol(this.layout);
        },
        /**
         * Ensure no series is currently selected
         */
        unselectSeries() {
            this.selectedCanvas = "";
            this.selectedSeriesElement = "";
            this.selectedSeries = 1;
        },
        firstCanvasLoadDone() {
            this.firstCanvasLoad = false;
        },
        /**
         * Add loaded image ids to store cache
         * 
         * @param {*} studyUID 
         * @param {*} seriesUID 
         * @param {*} imageIds 
         */
        AddImageIdsToChache(studyUID, seriesUID, imageIds) {
            var temp = {
                studyUID: studyUID,
                seriesUID: seriesUID,
                imageIds: imageIds
            }

            var record = this.imageIdsCache.filter(x => x.studyUID == studyUID && x.seriesUID == seriesUID);
            if (!record.length > 0)
                this.imageIdsCache.push(temp);
        },
        /**
         * Begin scrolling through stack displayed in given viewport
         * 
         * @param {*} viewport 
         */
        CinePlay(viewport) {
            var toolsStore = useToolsStore();
            var imageIds = viewport.imageIds;
            var currentImageId = viewport.getCurrentImageId();
            var seriesUID = currentImageId.substring(currentImageId.indexOf("series") + 7, currentImageId.indexOf("/instances"));
            if (this.cinedata.filter(x => x.seriesUID == seriesUID).length > 0) {
                this.CineSoftPlay();
            } else {

                var cwi = cornerstoneWADOImageLoader;
                var metadata = cwi.wadors.metaDataManager.get(imageIds[viewport.getTargetImageIdIndex()]);
                var numberOfFrames = metadata["00280008"] ? metadata["00280008"].Value[0] : 1;
                //make multiframe stack
                var multiFrameStack = [];
                for (let index = 0; index < numberOfFrames; index++) {
                    var frameId = `${currentImageId.split("frames/")[0]}frames/${index + 1}`
                    multiFrameStack.push(frameId);
                }
                viewport.setStack(multiFrameStack, 0);
                toolsStore.PlaySeries(); //act as if it is a series

                var temp = {
                    seriesUID: seriesUID,
                    playing: true
                }
                this.cinedata.push(temp);
            }
        },
        CineSoftPlay(viewport) {
            var toolsStore = useToolsStore();
            toolsStore.PlaySeries();
        },
        CineSoftStop(viewport) {
            var toolsStore = useToolsStore();
            toolsStore.StopSeries();
        },
        /**
         * Stop scrolling through image stack in given viewport
         * 
         * @param {*} viewport 
         */
        CineStop(viewport) {
            var currentImageId = viewport.getCurrentImageId();
            var cwi = cornerstoneWADOImageLoader;
            var metadata = cwi.wadors.metaDataManager.get(viewport.imageIds[viewport.getTargetImageIdIndex()]);
            var studyUID = metadata["0020000D"].Value[0];
            var seriesUID = metadata["0020000E"].Value[0];

            // //revert to normal
            var toolsStore = useToolsStore();
            // var study = this.studiesLoaded.filter(x => x.studyUID == studyUID)[0];
            // var instanceUID = currentImageId.substring(currentImageId.indexOf("instances") + 10, currentImageId.indexOf("/frames"));
            // var series = study.series.filter(x => x.seriesUID == seriesUID)[0];
            // var initialStack = series.imageIds;

            // var indexOfInstance = initialStack.indexOf(initialStack.filter(x => x.includes(instanceUID))[0]);
            // viewport.setStack(initialStack, indexOfInstance);
            toolsStore.StopSeries();

            //remove
            this.cinedata.splice(this.cinedata.indexOf(this.cinedata.filter(x => x.seriesUID == seriesUID)[0]), 1);

        },
        /**
         * 
         * @param {string} layout rowxcol 
         */
        SetLayout(layout) {
            this.layout.setLayoutFromString(layout);
        },
        CurrentStudy() {
            return this.studiesLoaded.filter(x => x.studyUID == this.currentStudy)[0];
        },
        GetImageIds(studyUID, seriesUID) {
            return this.cachedSeriesMetadata.filter(x => x.studyUID == studyUID && x.seriesUID == seriesUID)[0].imageIds
        },
        /**
         * Update selected canvas
         * 
         * @param {*} index 
         */
        SelectCanvas(index) {
            this.selectedCanvas = index;
        },
        /**
         * Update selected series
         * 
         * @param {*} study 
         * @param {*} series 
         * @param {*} element 
         */
        SelectSeries(study, series, element) {
            this.currentStudy = study;
            this.currentSeries = series.seriesUID;
            this.selectedSeriesElement = element;
            this.selectedSeries = series;

            const toolsStore = useToolsStore();
            const config = useConfigStore();

            if (config.refLinesEnabled) {
                toolsStore.ChangeRefLines();
            }
        },
        MultiPart_parse(body, contentType) {
            // Examples for content types:
            //      multipart/form-data; boundary="----7dd322351017c"; ...
            //      multipart/form-data; boundary=----7dd322351017c; ...
            var m = contentType.match(/boundary=(?:"([^"]+)"|([^;]+))/i);
            var s;
            var fieldName = "jpeg";
            if (!m) {
                throw new Error('Bad content-type header, no multipart boundary');
            }

            var boundary = m[1] || m[2];

            function Header_parse(header) {
                var headerFields = {};
                var matchResult = header.match(/^.*name="([^"]*)"$/);
                if (matchResult) headerFields.name = matchResult[1];
                return headerFields;
            }

            function rawStringToBuffer(str) {
                var idx, len = str.length,
                    arr = new Array(len);
                for (idx = 0; idx < len; ++idx) {
                    arr[idx] = str.charCodeAt(idx) & 0xFF;
                }
                return new Uint8Array(arr).buffer;
            }

            // \r\n is part of the boundary.
            boundary = '\r\n--' + boundary;

            var isRaw = typeof (body) !== 'string';

            if (isRaw) {
                var view = new Uint8Array(body);
                s = String.fromCharCode.apply(null, view);
            } else {
                s = body;
            }

            // Prepend what has been stripped by the body parsing mechanism.
            s = '\r\n' + s;

            var parts = s.split(new RegExp(boundary)),
                partsByName = {};

            // First part is a preamble, last part is closing '--'
            for (var i = 1; i < parts.length - 1; i++) {
                var subparts = parts[i].split('\r\n\r\n');
                var headers = subparts[0].split('\r\n');
                for (var j = 1; j < headers.length; j++) {
                    var headerFields = Header_parse(headers[j]);
                    if (headerFields.name) {
                        fieldName = headerFields.name;
                    }
                }

                partsByName[fieldName] = isRaw ? rawStringToBuffer(subparts[1]) : subparts[1];
            }

            return partsByName;
        },
        hexToBase64(str) {
            // var bString = "";
            // for (var i = 0; i < str.length; i += 2) {
            //     bString += String.fromCharCode(parseInt(str.substr(i, 2), 16));
            // }
            // return btoa(unescape(encodeURIComponent(bString)));
            var hexArray = str
                .replace(/\r|\n/g, "")
                .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
                .replace(/ +$/, "")
                .split(" ");
            var byteString = String.fromCharCode.apply(null, hexArray);

            return window.btoa(byteString);
        },
        getPdfData(data) {
            var contentType = data.headers["content-type"].match(/boundary=(?:"([^"]+)"|([^;]+))/i);
            let contentTypeParts = contentType.split(';');
            let boundary = contentTypeParts[1].split('=')[1];
            let parts = data.data.message.split(boundary).filter(part => part !== '--' && part !== '');
            let parsedParts = parts[1].split('\n').filter(part => part !== '');
            let shifted = parsedParts.splice(4, parsedParts.length - 2);
            return shifted.join();
        },
        async fetchImage(request) {
            var myImage;

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error("Network response was not OK");
                }
                const myBlob = await response.blob();
                myImage.src = URL.createObjectURL(myBlob);
            } catch (error) {
                console.error("Error:", error);
            }
        },
        /**
         * Handle retrieving instance level information from server and updating series instance list 
         * 
         * @param {*} series 
         * @param {*} studyUID 
         */
        async instanceLevelQido(series, studyUID) {
            const config = useConfigStore();
            var instancesQido = `${config.qidoRoot}/studies/${studyUID}/series/${series.seriesUID}/instances?includefield=00080018`;
            var resp = await axios.get(instancesQido);

            for (let index = 0; index < resp.data.length; index++) {
                const instanceUID = resp.data[index]["00080018"] ? resp.data[index]["00080018"].value ? resp.data[index]["00080018"].Value[0] : "" : "";

                const instance = {
                    instanceUID,
                }

                series.instanceData.push(instance);
            }
        },
        /**
         * Handle retrieving all series data for study from server and update studies series list
         * 
         * @param {*} study 
         * @returns 
         */
        async seriesLevelQido(study) {
            //SERIES LEVEL QIDO for all series in study
            const config = useConfigStore();

            let self = this;

            var tempStudyUID = study.studyUID;
            var seriesQIDO = `${config.qidoRoot}/studies/${tempStudyUID}/series?includefield=00080021&includefield=00201209&includefield=0008103E&includefield=0020000E&includefield=00200011&includefield=00080060`;

            await axios.get(seriesQIDO).then(function (res) {
                var data = res.data;
                data.forEach(ser => {
                    var series = {
                        initialized: false,
                        studyDescription: study.studyDescription,
                        seriesNumber: self.GetAttribute(ser["00200011"]),
                        seriesUID: self.GetAttribute(ser["0020000E"]),
                        seriesRelatedInstances: self.GetAttribute(ser["00201209"]),
                        seriesDescription: self.GetAttribute(ser["0008103E"]),
                        seriesDate: self.GetAttribute(ser["00080021"]),
                        studyDate: study.studyDate,
                        modality: self.GetAttribute(ser["00080060"]),
                        imageIds: [],
                        instanceData: [],
                    }

                    study.series.push(series);
                    if (!study.prior) {
                        self.mainSeriesUIDs.push(series.seriesUID);
                    } else {
                        self.priorSeriesUIDs.push(series.seriesUID);
                    }
                });
            })

            //sort series by series number
            study.series.sort((a, b) => a.seriesNumber - b.seriesNumber);

            return study;
        },
        /**
         * Retrieve related studies from specified route and add them to related instances list (A related instance shall not have
         * the same study UID as the main laoded study)
         * 
         * @param {*} apiRoute 
         * @param {*} mainStudyUID 
         */
        async relatedQido(apiRoute, mainStudyUID) {

            let self = this;
            await axios.get(apiRoute).then(function (res) {
                var data = res.data;
                if (res.data.length > 0) {
                    data.forEach(stud => {
                        var relatedStudy = {
                            patientName: self.GetAttribute(stud["00100010"]),
                            patientID: self.GetAttribute(stud["00100020"]),
                            patientAge: self.GetAttribute(stud["00101010"]),
                            DOB: self.GetAttribute(stud["00100030"]),
                            studyUID: self.GetAttribute(stud["0020000D"]),
                            studyDescription: self.GetAttribute(stud["00081030"]),
                            studyDate: self.GetAttribute(stud["00080020"]),
                            studyTime: self.GetAttribute(data[0]["00080030"]),
                            modalitiesInStudy: self.GetAttribute(stud["00080061"]),
                            institutionName: self.GetAttribute(stud["00080080"]),
                            series: [],
                            prior: true
                        }

                        //add everything but the study we have opened
                        if (relatedStudy.studyUID != mainStudyUID) {
                            let originalStudyDate = formatDicomDate(self.studiesLoaded[0].studyDate);
                            let priorStudyDate = formatDicomDate(relatedStudy.studyDate);

                            const wasPrior = compareDates(priorStudyDate, originalStudyDate);

                            if (wasPrior) {
                                let alreadyFound = self.relatedStudies.find(x => x.studyUID === relatedStudy.studyUID);

                                if (!alreadyFound) {
                                    self.relatedStudies.push(relatedStudy);
                                }
                            }
                        }
                    });
                }
            });
        },
        /**
         * Retrieve our main study from the server and handle retriving all nested data as well as related instances for that study
         * 
         * @param {*} studyUID 
         */
        async loadStudyFromServer(studyUID) {
            const config = useConfigStore();
            let self = this;

            //tell the user what is happening
            var element = document.getElementById("viewer-placeholder-text");
            element.innerHTML = "Loading metadata";
            var dots = document.getElementById("dots");
            dots.style.display = "block";

            //STUDY LEVEL QIDO
            var studyQido = `${config.qidoRoot}/studies?0020000D=${studyUID}&includefield=00101010&includefield=00100030&includefield=00081030&includefield=00100010&includefield=00100020&includefield=00080061&includefield=00080020&includefield=00080080&includefield=00080030`;
            await axios.get(studyQido).then(async function (res) {
                var data = res.data;

                if(!data) {
                    const dummyStudy = { // dummy data 
                        studyUID: "unknown",
                        DOB: "00000000",
                        studyDate: "00000000", 
                        studyTime: "0000.0000", 
                        series: [{
                            seriesUID: "unknown",
                            relatedInstances: 0
                        }]
                    }
                    
                    self.studiesLoaded.push(dummyStudy)

                    throw new Error(`Server returned no content for study level qido on studyUID ${studyUID}`)
                }

                var study = {
                    patientName: self.GetAttribute(data[0]["00100010"]),
                    patientID: self.GetAttribute(data[0]["00100020"]),
                    patientAge: self.GetAttribute(data[0]["00101010"]),
                    DOB: self.GetAttribute(data[0]["00100030"]),
                    studyUID: self.GetAttribute(data[0]["0020000D"]),
                    studyDescription: self.GetAttribute(data[0]["00081030"]),
                    studyDate: self.GetAttribute(data[0]["00080020"]),
                    studyTime: self.GetAttribute(data[0]["00080030"]),
                    modalitiesInStudy: self.GetAttribute(data[0]["00080061"]),
                    institutionName: self.GetAttribute(data[0]["00080080"]),
                    series: [],
                    prior: false,
                }
                study = await self.seriesLevelQido(study);

                for (let i = 0; i < study.series.length; i++) {
                    await self.instanceLevelQido(study.series[i], study.studyUID);
                }

                let seriesModalities = study.series.map(x => x.modality)

                let modality;

                /**
                 * cycle through ignoring "DOC" modality until genuine modality found 
                 * if there is only DOC modality then use DOC layout
                 */
                for (let i = 0; i < seriesModalities.length; i++) {
                    modality = seriesModalities[i];
                    let bool = (!(modality === "DOC") && !(modality === "SR") && !(modality === "DUMMY"))
                    if (bool) {
                        study.modalitiesInStudy = modality;

                        // break from loop 
                        i = seriesModalities.length;
                    }
                }

                let tempStudy = study;

                study.numberOfPriors = await getNumberOfPriors(study);

                self.studiesLoaded.push(tempStudy);
            }).then(async function (e) {
                let priors = config.showRelatedStudies;

                //related studies
                if (priors) {
                    config.getPriorDefaults();

                    let studiesLoaded = self.studiesLoaded.filter(x => x.studyUID == studyUID)[0]

                    let patientID = studiesLoaded.patientID; //using the patient id of the primary study
                    let patientDOB = studiesLoaded.DOB;
                    let patientName = studiesLoaded.patientName;

                    // PATIENT NAME MATCHES BY FIRST AND LAST SO THIS WORKS
                    const matchingPatientName = patientName.replace(" ", '^');
                    patientID = patientID.replace(/#/, '%23');

                    const matchByPatientIDIncludeFields = "&includefield=00100030&includefield=00100010";
                    const matchByPatientNameAndDOBIncludeFields = "&includefield=00100020";
                    const studyLevelIncludeFields = "&includefield=00080080&includefield=0020000D&includefield=00080061&includefield=00080020&includefield=00080030&includefield=00081030"

                    let patientIDQido = `${config.qidoRoot}/studies?fuzzymatching=true&00100020=${patientID}${matchByPatientIDIncludeFields}${studyLevelIncludeFields}`;
                    let patientNameDOBQido = `${config.qidoRoot}/studies?fuzzymatching=true&00100010=${matchingPatientName}&00100030=${patientDOB}${matchByPatientNameAndDOBIncludeFields}${studyLevelIncludeFields}`;

                    let relatedQIDO;
                    let loadAll = false;
                    let loadNone = false;

                    let priorDefaults = config.loadPriorsBy;

                    if (priorDefaults.loadPriorsByID && !priorDefaults.loadPriorsByNameDOB) {
                        relatedQIDO = patientIDQido;
                    }

                    if (priorDefaults.loadPriorsByNameDOB && !priorDefaults.loadPriorsByID) {
                        relatedQIDO = patientNameDOBQido;
                    }

                    if (priorDefaults.loadPriorsByID && priorDefaults.loadPriorsByNameDOB) {
                        loadAll = true;
                        relatedQIDO = patientIDQido;
                    }

                    if (priorDefaults.dontLoadPriors) loadNone = true;

                    if (!loadNone) {
                        if (!relatedQIDO) relatedQIDO = patientIDQido;


                        // GET PRIORS
                        await self.relatedQido(relatedQIDO, studyUID);

                        if (loadAll) { // load priors by id, name and dob
                            await self.relatedQido(patientNameDOBQido, studyUID);
                        }

                        self.relatedStudies.sort((a, b) => b.studyDate - a.studyDate);

                        for (let i = 0; i < self.relatedStudies.length; i++) {
                            let rel = self.relatedStudies[i];
                            rel = await self.seriesLevelQido(rel);
                            for (let j = 0; j < rel.series.length; j++) {
                                await self.instanceLevelQido(rel.series[j], rel.studyUID);
                            }
                            self.studiesLoaded.push(rel);
                        }
                    }
                }
                self.currentStudy = studyUID;
            }).then(async function (e) {
                // default tools, layouts and thumbnails
                await self.getAndSetAllDefaults();
                await self.setViewportLayoutAndToolsForStudy();
            }).catch((ex) => {
                console.warn(ex);
            }).finally(() => {
                self.preDataLoaded = true;

                if(self.studiesLoaded.length == 0) {
                    const alert = useAlertStore();
                    alert.showFailureAlert({text: "The study was not found, no study loaded"});
                }
            });
        },
        /**
         * Set the correct for current study based on modality defaults
         * 
         */
        async setViewportLayoutAndToolsForStudy() {
            var study = this.studiesLoaded.filter(x => x.studyUID == this.currentStudy);
            let seriesModalities = study[0].series.map(x => x.modality)
            let modality;

            /**
             * cycle through ignoring "DOC" modality until genuine modality found 
             * if there is only DOC modality then use DOC layout
             */
            for (let i = 0; i < seriesModalities.length; i++) {
                modality = seriesModalities[i];
                let bool = (!(modality === "DOC") && !(modality === "SR") && !(modality === "DUMMY"))
                if (bool) {
                    this.studiesLoaded.modalitiesInStudy = modality;

                    // break from loop 
                    i = seriesModalities.length;
                }
            }

            //var layoutCookie = UIstore.getCookie(modalitiesInStudy);
            var layoutForModality = this.defaults.find(x => x.modality == modality);
            var layout;

            // new modality detected, add to db 
            if (!layoutForModality) {
                const layoutForModality = {
                    modality: modality,
                    row: 2,
                    col: 2
                }
                this.defaults.push(layoutForModality);
                layout = `${layoutForModality.row}x${layoutForModality.col}`;

                localStorage.setItem("defaultLayouts", JSON.stringify(this.defaults));
            } else {
                layout = `${layoutForModality.row}x${layoutForModality.col}`;
            }

            if (layout) {
                this.SetLayout(layout);
            }

            //set default tools for STACK toolgroup
            const {
                Enums: csToolsEnums,
            } = cornerstoneTools;
            const { MouseBindings } = csToolsEnums;

            let toolsDefaults = JSON.parse(localStorage.getItem("defaultTools"));
            let toolDefault;
            if (toolsDefaults) {
                toolDefault = toolsDefaults.filter(x => x.modality === modality);
            }

            let res = {
                data: {
                    lm: "WindowLevel",
                    rm: "Zoom",
                    mm: "Pan"
                }
            }

            if (toolDefault && toolDefault.length > 0) {
                for (let i = 0; i < toolDefault.length; i++) {
                    switch (toolDefault[i].control) {
                        case 1:
                            res.data.lm = toolDefault[i].tool;
                            break;
                        case 2:
                            res.data.rm = toolDefault[i].tool;
                            break;
                        case 4:
                            res.data.mm = toolDefault[i].tool;
                            break;
                        default:
                            break;
                    }
                }
            } else {
                const newModalityTool = [
                    {
                        modality: modality,
                        control: 1,
                        tool: "WindowLevel"
                    },
                    {
                        modality: modality,
                        control: 2,
                        tool: "Zoom"
                    },
                    {
                        modality: modality,
                        control: 4,
                        tool: "Pan"
                    },
                ]

                if (toolsDefaults) {
                    toolsDefaults.push(...newModalityTool);
                } else {
                    toolsDefaults = [...newModalityTool];
                }
                localStorage.setItem("defaultTools", JSON.stringify(toolsDefaults));
            }

            var lm = res.data.lm;
            var rm = res.data.rm;
            var mm = res.data.mm;

            var toolsStore = useToolsStore();
            toolsStore.activeMouseTools[0] = lm;
            toolsStore.activeMouseTools[1] = mm;
            toolsStore.activeMouseTools[2] = rm;

            var TG = cornerstoneTools.ToolGroupManager.getToolGroup("STACK_TOOLS");
            TG.setToolActive(lm, {
                bindings: [{
                    mouseButton: MouseBindings.Primary,
                },],
            });

            TG.setToolActive(mm, {
                bindings: [{
                    mouseButton: MouseBindings.Auxiliary,
                },],
            });

            TG.setToolActive(rm, {
                bindings: [{
                    mouseButton: MouseBindings.Secondary,
                },],
            });
        },
        /**
         * Retrieve all default values and set them accordingly
         */
        async getAndSetAllDefaults() {
            const UIstore = useUIStore();
            const config = useConfigStore();

            let thumbnailDefaults = await JSON.parse(localStorage.getItem("defaultThumb"));

            if (thumbnailDefaults != null) {
                UIstore.thumbnailHSize = thumbnailDefaults['thumbnailHSize'];

                UIstore.thumbnailVSize = thumbnailDefaults['thumbnailVSize'];

                UIstore.thumbnailBottom = thumbnailDefaults['thumbnailHBottom'];

                UIstore.thumbnailLeft = thumbnailDefaults['thumbnailVleft'];
            }
            config.getPriorDefaults();
        },
        /**
         * Retrieve all default values
         * 
         * @param {*} query 
         * @returns 
         */
        GetAllDefaults(query) {
            return axios.get(query)
        },
        ProcessSR(data) {
            var resultHTML = "";
            var TopLevelattributesHTML = "";

            //List of top level attributes we are interested in
            var topLevelAttr = [
                { "Name": "00100010" },
                { "ID": "00100020" },
                { "Date of Birth": "00100030" },
                { "Sex": "00100040" },
                { "Completion": "0040A491" },
                { "Verification": "0040A493" }
            ];

            //Add the attributes to the html
            topLevelAttr.forEach(attr => {
                var tagName = Object.keys(attr)[0];
                var attrTag = Object.values(attr)[0]
                var attrValue = this.GetAttribute(data[attrTag]);


                //specific attributes that need extra conversion
                if (tagName == "Date of Birth") {
                    var year = attrValue.substring(0, 4);
                    var month = attrValue.substring(4, 6);
                    var day = attrValue.substring(6, 8);
                    attrValue = `${month}-${day}-${year}`;
                } else if (tagName == "Sex") {
                    if (attrValue == "M")
                        attrValue = "Male"
                    if (attrValue == "F")
                        attrValue = "Female"
                    if (attrValue == "O")
                        attrValue = "Other"
                    if (attrValue == "U")
                        attrValue = "Unknown"
                }

                var lineHTML = `<div class='sr-attribute'>${tagName} : ${attrValue}</div>`;
                TopLevelattributesHTML += lineHTML
            });

            //parse content sequence
            var ContentSequence = { "Content Sequence": "0040A730" };
            var contentSQname = Object.keys(ContentSequence)[0];
            var contentSQtag = Object.values(ContentSequence)[0]
            var contentSQvalue = data[contentSQtag];


            //added warning until sr parse is done properly
            var warningHTML = `<div class='sr-warning'>!! SR is not fully supported in this verion !!</div>`;

            //Assemble the SR html 
            resultHTML += `<div class='sr-data'> ` +
                `<div class='sr-data-title'> Structured Report (SR)</div><hr style="width: 50%; border: 1px solid #9b7a3c;"/>` +
                `<br/>` +
                `${TopLevelattributesHTML}` +
                `<br/><hr/>` +
                `${warningHTML}` +
                `</div>`;
            return resultHTML;
        },
        /**
         * Extract dicom attribute value from the provided object or list
         * @param {*} attribute 
         * @returns 
         */
        GetAttribute(attribute) {
            if (attribute && attribute.Value) {
                if (attribute.Value[0].Alphabetic)
                    return attribute.Value[0].Alphabetic;
                else
                    return attribute.Value[0];
            } else
                return "";
        },
        /**
         * Handle scrolling multiple stacks at once when user has multi selected
         * 
         * @param {*} event 
         * @returns 
         */
        handleMultiStackScroll(event) {
            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (visibleMultiSelected.length == 0) {
                return;
            }
            //-1 = up : 1 = down 
            const scrollDirection = event.deltaY < 0 ? -1 : 1;
            ////Update image indexes for multi selected viewports
            this.multiSelectedViewports.forEach((viewport) => {
                viewport.scroll(scrollDirection);
            })
            // this.multiSelectedViewports.map((viewport) => {
            //     viewport.scroll(scrollDirection);
            // })
        },
        /**
         * Remove multi selected status from all viewports
         * 
         * @param {*} viewport 
         */
        removeMultiSelected(viewport) {
            const index = this.multiSelectedViewports.indexOf(viewport);
            if (index !== -1) {
                this.multiSelectedViewports.splice(index, 1);
            }

            // const cacheIndex = this.multiSelectedViewportCache.indexOf(viewport);

            // if(cacheIndex !== -1) {
            //     this.multiSelectedViewportCache.splice(cacheIndex, 1);
            // }
        }
    }
});