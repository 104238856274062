import { defineStore } from "pinia";

export const useConfigStore = defineStore("config", {
    state: () => ({
        wadoRoot: "https://dicomserver.co.uk:8989/wado",
        qidoRoot: "https://dicomserver.co.uk:8989/qido",
        dblclickThumbnail: true,
        thumbnailsDefault: {
            enabled: true,
            sizeOnCtrl: false, // Only show size dropdown when holding ctrl
            sizeH: 'medium', // 'small' | 'medium' | 'large'
            sizeV: 'medium', // 'small' | 'medium' | 'large'
            reactive: true,
            allowChangeOrientation: false,
            allowChangeLocation: true,
        },
        canvasPatientDetails: {
            age: true,
            sex: false,
            seriesNumber: true,
            name: true,
            desc: true
        },
        showRelatedStudies: true,
        refLinesEnabled: true,
        loadPriorsBy: {
            dontLoadPriors: false,
            loadPriorsByID: true,
            loadPriorsByNameDOB: false
        }
    }),
    getters: {

    },
    actions: {
        getPriorDefaults() {
            let priorDefaults = JSON.parse(localStorage.getItem("defaultPrior"));

            if (!priorDefaults) {
                this.loadPriorsBy.dontLoadPriors = false;
                this.loadPriorsBy.loadPriorsByID = true;
                this.loadPriorsBy.loadPriorsByNameDOB = false;
            } else {
                this.loadPriorsBy.dontLoadPriors = priorDefaults.dontLoadPriors;
                this.loadPriorsBy.loadPriorsByID = priorDefaults.loadPriorsByID;
                this.loadPriorsBy.loadPriorsByNameDOB = priorDefaults.loadPriorsByNameDOB;
            }
        },
        savePriorDefaults(dontLoadPriors, loadPriorsByID, loadPriorsByNameDOB) {
            const priorDefaults = {
                dontLoadPriors: dontLoadPriors,
                loadPriorsByID: loadPriorsByID,
                loadPriorsByNameDOB: loadPriorsByNameDOB,
            }

            localStorage.setItem("defaultPrior", JSON.stringify(priorDefaults));
        }
    }
});