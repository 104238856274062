export default class {
    _row = 1;
    _col = 1;

    constructor({row = 1, col = 1}) {
        this._row = row;
        this._col = col;
    }

    get row() {
        return this._row;
    }

    get col() {
        return this._col;
    }

    set row(row) {
        this._row = row;
    }

    set col(col) {
        this._col = col;
    }

    setLayoutFromString(rowxcol) {
        const parts = rowxcol.split('x');

        this.row = parts[0];
        this.col = parts[1];
    }

    getFields() {
        return {
            row: this.row,
            col: this.col
        }
    }

    clear() {
        this.col = 1;
        this.row = 1;
    }

    inBounds(row, col) {
        return row <= this.row && col <= this.col;
    }

    toString() {
        return `${this.row}x${this.col}`;
    }
}