import { createApp } from 'vue'
import { createPinia } from 'pinia';
import 'material-icons/iconfont/material-icons.css';
import App from './App.vue'
import router from './router'
import * as cornerstone3D from "@cornerstonejs/core";
import * as cornerstone3DTools from "@cornerstonejs/tools";
import dicomParser from 'dicom-parser';
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";

// context menu import
import ContextMenu from '@imengyu/vue3-context-menu'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'

//1. Initialize Cornerstone and Cornerstone Tools
cornerstone3D
    .init()
    .then(cornerstone3DTools.init())
    .then(() => {
        //2. Configure cornerstoneWADOImageLoader
        cornerstoneWADOImageLoader.external.cornerstone = cornerstone3D;
        cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

        /**
         * ADDED to try and combat 512 image limit on volume image loading
         * but did not work
         * 
         */
        // cornerstoneWADOImageLoader.external.cornerstone = cornerstone3D;

        // cornerstone3D.imageLoader.registerImageLoader(
        //   "http",
        //   cornerstoneWADOImageLoader.loadImage
        // );

        cornerstoneWADOImageLoader.configure({
            useWebWorkers: true,
            decodeConfig: {
                convertFloatPixelDataToInt: false,
            },
        });

        var config = {
            maxWebWorkers: navigator.hardwareConcurrency / 2 || 1,
            startWebWorkersOnDemand: true,
            taskConfiguration: {
                decodeTask: {
                    initializeCodecsOnStartup: true,
                    usePDFJS: false,
                    strict: false,
                },
            },
        };
        cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
        //reference lines do not work correctly with CPU rendering
        //cornerstone3D.setUseCPURendering(true)

        //3. Create the Vue 3 app and add Pinia + Vue Router
        createApp(App)
            .use(createPinia())
            .use(router)
            .use(ContextMenu)
            .mount('#app');
    });