import { useConfigStore } from "../store/config";
import axios from "axios";

/**
 * Returns the number of prior studies in study
 * 
 * @returns "{ number of priors using id, number of priors using name and dob}"
 */
export default async function getNumberOfPriors(study) {
    const config = useConfigStore();

    let patientID = study.patientID; //using the patient id of the primary study
    let patientDOB = study.DOB;
    let patientName = study.patientName;

    // PATIENT NAME MATCHES BY FIRST AND LAST SO THIS WORKS
    const matchingPatientName = patientName.replace(/ /g, '^');

    // THIS WILL CHANGE BASED ON HANGING PROTOCOL
    let priorIDQIDO = `${config.qidoRoot}/studies?fuzzymatching=true&00100020=${patientID}`;

    let priorNameAndDOBQIDO = `${config.qidoRoot}/studies?fuzzymatching=true&00100010=${matchingPatientName}&00100030=${patientDOB}`;

    let numberOfPriorsWithID = 0;
    let numberOfPriorsWithNameAndDOB = 0;

    await axios.get(priorIDQIDO).then(res => {
        numberOfPriorsWithID = res.data.length - 1;
    }).catch(() => null);

    await axios.get(priorNameAndDOBQIDO).then(res => {
        numberOfPriorsWithNameAndDOB = res.data.length - 1;
    }).catch(() => null);

    // NOT RETURNING: DO
    const numberPriors = {
        numberOfPriorsWithID,
        numberOfPriorsWithNameAndDOB
    }
    return numberPriors
}