import { create, all } from 'mathjs';

/**
 * Calculate image plane based on image orientation metadata 
 * 
 * @param {number[]} vectorCosines [Xx,Xy,Xz,Yx,Yy,Yz]
 * @returns {string} "Axial", "Sagittal", "Coronal" or "Oblique"
 */
export default (vectorCosines) => {
    // instantiate math object
    const config = {};
    const math = create(all, config);

    // Get row and column vectors
    const image_x = vectorCosines.slice(0, 3);
    const image_y = vectorCosines.slice(3);

    // Calculate the normal vector to the image plane
    // cross product of row and column vectors
    const normalVector = [
        image_x[1] * image_y[2] - image_x[2] * image_y[1],
        image_x[2] * image_y[0] - image_x[0] * image_y[2],
        image_x[0] * image_y[1] - image_x[1] * image_y[0]
    ];

    // Determine the orientation based on the normal vector
    if ((normalVector[0] === 1 || normalVector[0] === -1) && normalVector[1] === 0 && normalVector[2] === 0) {
        return 'Sagittal';
    } else if (normalVector[0] === 0 && (normalVector[1] === 1 || normalVector[1] === -1) && normalVector[2] === 0) {
        return 'Coronal';
    } else if (normalVector[0] === 0 && normalVector[1] === 0 && (normalVector[2] === 1 || normalVector[2] === -1)) {
        return 'Transverse (Axial)';
    }

    // possibly dealing with oblique

    // Calculate cross product
    const image_z = math.cross(image_x, image_y);

    // calculate absolute value or cross product vector
    const abs_image_z = image_z.map(Math.abs);

    // find the index of the maximum value of absolute corss product vectors
    const max_abs_image_z = Math.max(...abs_image_z);

    /**
     * Calculate euclidean norm of each vector ->
     * caculate dot product of above with each vector ->
     * calculate inverse cosine of above
     * 
     * returns result in radians
     */
    let angle_xz = math.acos(math.dot(image_x, [1, 0, 0]) / math.norm(image_x));
    let angle_yz = math.acos(math.dot(image_y, [0, 1, 0]) / math.norm(image_y));
    let angle_xy = math.acos(math.dot(image_z, [0, 0, 1]) / math.norm(image_z));

    /**
     * Convert from radians to pi
     */
    angle_xz *= (180 / Math.PI);
    angle_yz *= (180 / Math.PI);
    angle_xy *= (180 / Math.PI);
    // Twenty degree viewing angle tolerance 
    const tolerance = 20
    // Check if angles are within tolerance of 90 degrees or 0 degrees
    if (
        (Math.abs(90 - angle_xz) <= tolerance || Math.abs(angle_xz) <= tolerance) &&
        (Math.abs(90 - angle_yz) <= tolerance || Math.abs(angle_yz) <= tolerance) &&
        (Math.abs(90 - angle_xy) <= tolerance || Math.abs(angle_xy) <= tolerance)
    ) {
        // Inside tolerance is standard viewing angle
        if (Math.round(Math.abs(normalVector[0])) == 1 && Math.round(Math.abs(normalVector[1])) == 0 && Math.round(Math.abs(normalVector[2])) == 0) {
            return 'Sagittal';
        } else if (Math.round(Math.abs(normalVector[1])) == 1 && Math.round(Math.abs(normalVector[0])) == 0 && Math.round(Math.abs(normalVector[2])) == 0) {
            return 'Coronal';
        } else if (Math.round(Math.abs(normalVector[2])) == 1 && Math.round(Math.abs(normalVector[1])) == 0 && Math.round(Math.abs(normalVector[0])) == 0) {
            return 'Transverse (Axial)';
        } else {
            "Oblique";
        }
    } else {
        // Outside of tolerance is considered oblique
        return "Oblique";
    }
}