import { defineStore } from "pinia";
import { useToolsStore } from './tools';
import { useMainStore } from './main';
import { useConfigStore } from './config';
import * as cornerstone3D from "@cornerstonejs/core";
import * as cornerstoneTools from '@cornerstonejs/tools';
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";
import { useUIStore } from "./ui";

export const useEventsStore = defineStore("events", {
    state: () => ({
        multiSelectedAll: false,
        stackSynchronizerId: 'STACK_SYNCHRONIZER_ID',
        viewportProperties: [],
    }),
    getters: {

    },
    actions: {
        /**
         * Handle emptying selected viewports by removing currently viewed series and 
         * leaving a blank canvas
         * 
         * DO: BUG ON MULTI SELECT
         */
        emptySelectedViewport() {
            const mainStore = useMainStore();

            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;
            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                let canvases = JSON.parse(JSON.stringify(mainStore.canvases));
                for (let i = 0; i < visibleMultiSelected.length; i++) {
                    const ele = visibleMultiSelected[i];


                    let elem = ele.querySelector('[data-viewport-uid]');
                    const id = elem.attributes["data-viewport-uid"].value;
                    const canvasId = parseInt(id.split('_')[1]);

                    canvases[canvasId - 1] = {
                        id: canvasId,
                        type: "EMPTY",
                        seriesUID: "",
                        studyUID: "",
                        imageIds: []
                    }
                }

                while (visibleMultiSelected.length > 0) {
                    visibleMultiSelected[0].classList.remove("multi-selected");
                }

                mainStore.canvases = canvases
                mainStore.multiSelectedViewports = [];

            } else if (mainStore.selectedSeriesElement) {
                let elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                const id = elem.attributes["data-viewport-uid"].value;
                // let renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                // let viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                const canvasId = parseInt(id.split('_')[1]);

                mainStore.canvases[canvasId - 1] = {
                    id: canvasId,
                    type: "EMPTY",
                    seriesUID: "",
                    studyUID: "",
                    imageIds: []
                }

                mainStore.unselectSeries();
            }
        },
        /**
         * Handle user initiating thumbnail focus (usually via double click)
         * 
         * @param {*} series Series data
         * @param {*} e event 
         */
        FocusCanvasThumbnail(series, e) {
            let config = useConfigStore();
            let mainStore = useMainStore();

            var element = !e.target.classList.contains("series-thumbnail-image")
                ? e.target.parentElement.parentElement.parentElement
                : e.target.parentElement;

            var studyUID = element.querySelector(".dragable-thumb").study;

            if (config.dblclickThumbnail && !mainStore.isEchoLayout) {
                mainStore.toggleFocusedSeries(studyUID, series.seriesUID);
            }
        },
        /**
         * Handle user initiating drag of a thumbnail element
         * 
         * @param {*} e onDrag event 
         */
        thumbnailDragStart(e) {
            e.dataTransfer.dropEffect = "move";
            e.dataTransfer.effectAllowed = "move";
            var element = !e.target.classList.contains("series-thumbnail-image")
                ? e.target.parentElement.parentElement.parentElement
                : e.target.parentElement;
            element.querySelector(".dragable-thumb").study;
            var studyUID = element.querySelector(".dragable-thumb").study;
            var seriesUID = element.querySelector(".dragable-thumb").series;

            e.dataTransfer.setData("studyUID", studyUID);
            e.dataTransfer.setData("seriesUID", seriesUID);
            e.dataTransfer.setData("source", "thumbnail");
        },
        /**
         * Handle creation of and src mapping to thumbnail image elements in 
         * thumbnail on mounted life cycle hook
         * 
         * @param {*} thumbnails All thumbnail data
         */
        thumbnailsOnMounted(thumbnails) {
            const config = useConfigStore();
            for (let indexStudy = 0; indexStudy < thumbnails.length; indexStudy++) {
                const thumbnailData = thumbnails[indexStudy];

                var allSeries = thumbnailData.series;

                for (
                    let indexSeries = 0;
                    indexSeries < allSeries.length;
                    indexSeries++
                ) {
                    const series = allSeries[indexSeries];
                    var thumbImageContainer = document
                        .querySelectorAll(
                            `.series-thumbnail[series="${indexSeries}"][study="${thumbnailData.studyUID}"]`
                        )[0]
                        .querySelector(".series-thumbnail-image");
                    const element = document.createElement("div");
                    element.classList.add("thumbnail-container-" + (indexSeries + 1));
                    element.classList.add("dragable-thumb");
                    element.style.width = "100%";
                    element.style.height = "100%";
                    element.style.position = "relative";
                    element.series = series.seriesUID;
                    element.study = thumbnailData.studyUID;
                    element.style.cursor = "default !important"; //override cornerstone tool cursors
                    thumbImageContainer.series = series.seriesUID;

                    if (series.modality != "SR") {
                        var imgElement = document.createElement("img");
                        imgElement.width = "100% !important";
                        imgElement.height = "100% !important";
                        element.appendChild(imgElement);
                        thumbImageContainer.appendChild(element);

                        imgElement.crossOrigin = "anonymous";
                        imgElement.fetchPriority = "high";

                        var jpegRequest = `${config.wadoRoot}/studies/${thumbnailData.studyUID}/series/${series.seriesUID}/rendered`;
                        imgElement.src = jpegRequest;
                    } else if (series.modality == "SR") {
                        var srElement = document.createElement("div");
                        srElement.classList.add("sr-thumb");
                        srElement.innerHTML =
                            "<div class='sr-title'>Structured <br/> report <br/> (SR)</div>";
                        element.appendChild(srElement);
                        thumbImageContainer.appendChild(element);
                    }
                }
            }
        },
        /**
         * Handle user key press event
         * 
         * Backspace - Empty Viewport Canvas
         * 
         * Shift - multiselect all 
         * 
         * Arrow Up - Move thumbnail bar from bottom to top
         * 
         * Arrow Down - Move thumbnail bar from top to bottom
         * 
         * Arrow Left - Move thumbnail bar from right to left
         * 
         * Arrow Right - Move thumbnail bar from left to right
         * 
         * End - Display final image in selcted stack(s)
         * 
         * Home - Display first image in selectd stack(s)
         * 
         * PageUp - Scroll selected Series to next series number
         * 
         * PageDown - Scroll selected series to previous series number
         * 
         * 1 - Change windowing preset to CTAbdomen in selected stack(s)
         * 
         * 2 - Change windowing preset to CTAngio in selected stack(s)
         * 
         * 3 - Change windowing preset to CTBone in selected stack(s)
         * 
         * 4 - Change windowing preset to CTBrain in selected stack(s)
         * 
         * 5 - Change windowing preset to CTChest in selected stack(s)
         * 
         * 6 - Change windowing preset to CTLungs in selected stack(s)
         * 
         * Space - Reset windowing value in selected stack(s)
         * 
         * @param {*} keyboardEvent 
         */
        KeyboardEvent(keyboardEvent) {
            const main = useMainStore();
            const toolStore = useToolsStore();
            const UIstore = useUIStore();
            const configStore = useConfigStore();

            const settingsOpen = UIstore.settingsModalActive;
            const metadataModalOpen = UIstore.meataDataModalOpen;

            if (settingsOpen || metadataModalOpen) return;

            const thumbnailDefaultsEnabled = configStore.thumbnailsDefault.enabled;
            const changeThumbnailOrientation = configStore.thumbnailsDefault.allowChangeOrientation;
            const changeThumbnailLocation = configStore.thumbnailsDefault.allowChangeLocation;
            const changeThumbnail = changeThumbnailLocation || changeThumbnailOrientation;

            keyboardEvent = keyboardEvent || window.event;
            switch (keyboardEvent.key) {
                case 'Backspace':
                    this.emptySelectedViewport();
                    break;
                case 'Shift':
                    this.multiSelectAll();
                    break;
                case 'ArrowUp':
                    if (keyboardEvent.ctrlKey && changeThumbnail && thumbnailDefaultsEnabled) {
                        if (changeThumbnailLocation) {
                            UIstore.thumbnailBottom = false;
                        }

                        if (changeThumbnailOrientation) {
                            UIstore.thumbnailVertical = false;
                        }
                        UIstore.UpdateDefaultThumbnails();
                    }
                    break;
                case 'ArrowDown':
                    if (keyboardEvent.ctrlKey && changeThumbnail && thumbnailDefaultsEnabled) {
                        if (changeThumbnailLocation) {
                            UIstore.thumbnailBottom = true;
                        }

                        if (changeThumbnailOrientation) {
                            UIstore.thumbnailVertical = false;
                        }
                        UIstore.UpdateDefaultThumbnails();
                    }
                    break;
                case 'ArrowLeft':
                    if (keyboardEvent.ctrlKey && changeThumbnail && thumbnailDefaultsEnabled) {
                        if (changeThumbnailLocation) {
                            UIstore.thumbnailLeft = true;
                        }

                        if (changeThumbnailOrientation) {
                            UIstore.thumbnailVertical = true;
                        }
                        UIstore.UpdateDefaultThumbnails();
                    }
                    break;
                case 'ArrowRight':
                    if (keyboardEvent.ctrlKey && changeThumbnail && thumbnailDefaultsEnabled) {
                        if (changeThumbnailLocation) {
                            UIstore.thumbnailLeft = false;
                        }

                        if (changeThumbnailOrientation) {
                            UIstore.thumbnailVertical = true;
                        }
                        UIstore.UpdateDefaultThumbnails();
                    }
                    break;
                case 'PageUp':
                    // TODO
                    main.handleSeriesScroll(true);

                    break;
                case 'PageDown':
                    // TODO
                    main.handleSeriesScroll(false);
                    break;
                case 'End':
                    toolStore.LastIndex();
                    break;
                case 'Home':
                    toolStore.FirstIndex();
                    break;
                case '1':
                    if (!toolStore.settingsOpened) {
                        keyboardEvent.preventDefault();
                        toolStore.ChangeToWindowingPreset("CTAbdomen");
                    }
                    break;
                case '2':
                    if (!toolStore.settingsOpened) {
                        keyboardEvent.preventDefault();
                        toolStore.ChangeToWindowingPreset("CTAngio");
                    }
                    break;
                case '3':
                    if (!toolStore.settingsOpened) {
                        keyboardEvent.preventDefault();
                        toolStore.ChangeToWindowingPreset("CTBone");
                    }
                    break;
                case '4':
                    if (!toolStore.settingsOpened) {
                        keyboardEvent.preventDefault();
                        toolStore.ChangeToWindowingPreset("CTBrain");
                    }
                    break;
                case '5':
                    if (!toolStore.settingsOpened) {
                        keyboardEvent.preventDefault();
                        toolStore.ChangeToWindowingPreset("CTChest");
                    }
                    break;
                case '6':
                    if (!toolStore.settingsOpened) {
                        keyboardEvent.preventDefault();
                        toolStore.ChangeToWindowingPreset("CTLungs");
                    }
                    break;
                case ' ':
                    if (!toolStore.settingsOpened) {
                        keyboardEvent.preventDefault();
                        toolStore.ResetWWWC();
                    }

                    break;
                case 'Escape':
                    this.navigateToWorkflow();
                    break
                default:
                    break;
            }
        },
        /**
         * Runs when new image enters viewport and prior to image render
         * 
         * @param {*} _element 
         * @param {*} viewport 
         */
        StackNewImageEvent(_element, viewport) {
            const self = this;

            _element.addEventListener(cornerstone3D.EVENTS.STACK_NEW_IMAGE, function (e) {
                const element = e.target;
                const canvas = element;

                //if the element was removed from the target already
                if (!canvas.parentElement)
                    return;

                const renderingEngine = cornerstone3D.getRenderingEngine(element.attributes["data-rendering-engine-uid"].value);
                const viewport = renderingEngine.getViewport(element.attributes["data-viewport-uid"].value);

                const zoomFactor = viewport.getZoom();
                const pan = viewport.getPan();

                const point2 = { x: pan[0], y: pan[1] }
                if (zoomFactor != 1 || point2.x != 0 || point2.y != 0) {
                    const vp = {
                        id: viewport.id,
                        zoomFactor: zoomFactor,
                        pan: point2
                    }
                    let vpIndex = self.viewportProperties.findIndex(x => x.id === viewport.id);

                    if (vpIndex) {
                        self.viewportProperties.splice(vpIndex, 1);
                    }

                    self.viewportProperties.push(vp)

                }
            });
        },
        /**
         * Clears the viewportProperties item with given id
         * 
         * @param {*} id 
         */
        clearViewportProperty(id) {
            const vpIndex = this.viewportProperties.findIndex(x => x.id === id);

            if (vpIndex !== -1) {
                this.viewportProperties.splice(vpIndex, 1);
            }
        },
        /**
         * Handle user clicking viewport
         * 
         * Normal click will "select" series allowing you to manipulate it with
         * key events and certain toolbar items
         * 
         * Ctrl click allows you to select multiple viewports but disables standard syncing
         * behaviour
         * @param {*} e click event
         * @param {*} viewport 
         * @param {*} canvasContainerElements all canvas elements
         */
        clickEvent(e, viewport, canvasContainerElements) {
            const mainStore = useMainStore();
            //Removing stack scroll functionality when multiselecting 
            const {
                ToolGroupManager,
                StackScrollMouseWheelTool,
                SynchronizerManager
            } = cornerstoneTools;

            ToolGroupManager.getToolGroup('STACK_TOOLS').setToolDisabled(StackScrollMouseWheelTool.toolName);

            this.multiSelectedAll = false;

            var isRightMB;

            if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                isRightMB = e.which == 3;
            else if ("button" in e)  // IE, Opera 
                isRightMB = e.button == 2;

            if (isRightMB && !e.ctrlKey) return

            mainStore.unselectSeries();

            //multiselect when ctrl + click
            if (e.ctrlKey && !isRightMB) {
                //Remove sync functionality when multiselecting 
                const synchronizer = SynchronizerManager.getSynchronizer(this.stackSynchronizerId);
                synchronizer.destroy();

                //Remove main select when multiselecting
                for (let index = 0; index < canvasContainerElements.length; index++) {
                    const canvasContainer = canvasContainerElements[index];
                    canvasContainer.classList.remove("selected");
                }

                var includesViewport = mainStore.multiSelectedViewports.includes(viewport);
                let visibleMultiSelected = document.getElementsByClassName("multi-selected");
                //Check if viewport is already multiselected, if it is remove it, if not add it
                if (!includesViewport) {
                    if (visibleMultiSelected.length == 0) {
                        mainStore.multiSelectedViewports = [];
                    }
                    mainStore.multiSelectedViewports.push(viewport);

                    e.target.closest(".canvas-container").classList.add("multi-selected");


                    e.target.removeEventListener("wheel", mainStore.handleMultiStackScroll);
                    e.target.addEventListener("wheel", mainStore.handleMultiStackScroll)
                } else {
                    // If going from standard select to multi then check memory. If stored enable all again.
                    if (visibleMultiSelected.length == 0) {
                        for (let i = 0; i < mainStore.multiSelectedViewports.length; i++) {
                            mainStore.multiSelectedViewports[i].element.parentNode.parentNode.classList.add("multi-selected")
                        }
                    } else {
                        mainStore.removeMultiSelected(viewport);

                        e.target.closest(".canvas-container").classList.remove("multi-selected");
                        e.target.removeEventListener("wheel", mainStore.handleMultiStackScroll);
                    }

                    //Ensure stack scroll is available when not multiselecting
                    if (mainStore.multiSelectedViewports.length === 0) {
                        ToolGroupManager.getToolGroup('STACK_TOOLS').setToolActive(StackScrollMouseWheelTool.toolName);
                    }
                }

                let multiSelected = document.getElementsByClassName("multi-selected");
                let canvases = document.getElementsByClassName("canvas")
                let viewportCount = 0;
                for (let i = 0; i < canvases.length; i++) {
                    let element = canvases[i].childNodes[0].attributes;
                    if (element["data-rendering-engine-uid"]) {
                        viewportCount++
                    }
                }

                // determine whether new multi selected means all viewports are mutliselected
                this.multiSelectedAll = multiSelected.length == viewportCount


                return;
            }

            //Ensure stack scroll is available when not multiselecting
            ToolGroupManager.getToolGroup('STACK_TOOLS').setToolActive(StackScrollMouseWheelTool.toolName);

            //var canvasContainerElements = document.getElementsByClassName("canvas-container");
            for (let index = 0; index < canvasContainerElements.length; index++) {
                const canvasContainer = canvasContainerElements[index];
                canvasContainer.classList.remove("selected");
                canvasContainer.classList.remove("multi-selected");
            }

            // mainStore.multiSelectedViewports = [];
            // mainStore.multiSelectedAll = false;

            e.target.closest(".canvas-container").classList.add("selected");

            var canvasToSelect = e.target.closest(".canvas").id.split("-")[1];
            mainStore.SelectCanvas(parseInt(canvasToSelect));

            var currentImageIdIndex = viewport.getTargetImageIdIndex();
            var cwi = cornerstoneWADOImageLoader;
            var metadata = cwi.wadors.metaDataManager.get(viewport.imageIds[currentImageIdIndex]);
            var studyUID = metadata["0020000D"].Value[0];
            var seriesUID = metadata["0020000E"].Value[0];

            var thisStudy = mainStore.studiesLoaded.filter(x => x.studyUID == studyUID)[0];
            var selectedSeries = thisStudy.series.filter(x => x.seriesUID == seriesUID)[0];

            var selectedElement = e.target;
            mainStore.SelectSeries(studyUID, selectedSeries, selectedElement);
        },
        /**
         * Select all on screen canvases for manipulation via tools or key press
         */
        multiSelectAll() {
            const mainStore = useMainStore();

            mainStore.unselectSeries();

            //Removing stack scroll functionality when multiselecting 
            const {
                ToolGroupManager,
                StackScrollMouseWheelTool,
            } = cornerstoneTools;

            ToolGroupManager.getToolGroup('STACK_TOOLS').setToolDisabled(StackScrollMouseWheelTool.toolName);

            //Remove sync functionality when multiselecting 
            const {
                SynchronizerManager,
            } = cornerstoneTools;
            const synchronizer = SynchronizerManager.getSynchronizer('STACK_SYNCHRONIZER_ID');
            synchronizer.destroy();


            //Remove main select when multiselecting
            let canvasContainerElements = document.getElementsByClassName("canvas-container");
            for (let index = 0; index < canvasContainerElements.length; index++) {
                const canvasContainer = canvasContainerElements[index];
                canvasContainer.classList.remove("selected");
                canvasContainer.classList.remove("multi-selected");
                mainStore.multiSelectedViewports = [];
            }

            // leave all unselected 
            if (this.multiSelectedAll) {
                this.multiSelectedAll = false;
                return;
            }

            this.multiSelectedAll = true;

            for (let index = 0; index < canvasContainerElements.length; index++) {
                const canvasContainer = canvasContainerElements[index];

                let element = canvasContainer.getElementsByClassName("canvas")[0].childNodes[0].attributes;
                if (!element["data-rendering-engine-uid"]) continue // Check that canvas contains a cornerstone viewport


                let renderingEngine = cornerstone3D.getRenderingEngine(element["data-rendering-engine-uid"].value);
                let viewport = renderingEngine.getViewport(element["data-viewport-uid"].value);
                mainStore.multiSelectedViewports.push(viewport);



                canvasContainer.classList.add("multi-selected");
                canvasContainer.removeEventListener("wheel", mainStore.handleMultiStackScroll);
                canvasContainer.addEventListener("wheel", mainStore.handleMultiStackScroll)
            }
        }
    }
});